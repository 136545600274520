import { BrowserRouter, Routes, Route } from "react-router-dom";

import App from "../../App";
import { AuthProvider } from "./../../context/AuthContext";
import { NotificationProvider } from "../../context/NotificationContext";

// Pages imports
import PMUPage from "../../pages/PMUPage";
import AdminPage from "../../pages/AdminPage";
import NotFound from "../../pages/NotFound";
import NoAccessPage from "../../pages/NoAccessPage";
import ApprovalsPage from "../../pages/ApprovalsPage";
import UserGuidePage from "../../pages/UserGuidePage";
import DebounceContextProvider from "../../context/DebounceContext";

const Router = () => {
  return (
    <BrowserRouter basename={`${process.env.REACT_APP_BASE_NAME}`}>
      <AuthProvider>
        <NotificationProvider>
          <DebounceContextProvider>
            <Routes>
              <Route path="/" element={<App />}>
                <Route index element={<PMUPage />} />
                <Route path="approval-queues" element={<ApprovalsPage />} />
                <Route
                  path="approval-queues/:queueId"
                  element={<ApprovalsPage />}
                />
                <Route path="admin" element={<AdminPage />} />
                <Route path="admin/:logId" element={<AdminPage />} />
                <Route path="user-guide" element={<UserGuidePage />} />
                <Route path="no-access" element={<NoAccessPage />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </DebounceContextProvider>
        </NotificationProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Router;
