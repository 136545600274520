import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import UserGuideAdmin from "../components/UserGuideAdmin";
import UserGuideBase from "../components/UserGuideBase";
import AuthContext from "../context/AuthContext";

const UserGuidePage = () => {
  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;

  const scrollToTop = () => {
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div className="userguide-page">
        {hasPermission("api.approve_pmu_queue") ? (
          <UserGuideAdmin />
        ) : (
          <UserGuideBase />
        )}
      </div>
      <button className="scroll-to-top" onClick={scrollToTop}>
        <FontAwesomeIcon
          icon={faChevronUp}
          color="#fff"
          className="text-align-center"
        />
      </button>
    </>
  );
};

export default UserGuidePage;
