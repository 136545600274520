import { useState, ReactNode, useEffect } from "react";
import { ToastContainer, Zoom } from "react-toastify";
import SideNav from "./SideNav";
import TopNav from "./TopNav";
import { useLocation, matchPath } from "react-router-dom";

interface Props {
  children: ReactNode;
}

const Layout = (props: Props) => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const { children } = props;
  const location = useLocation();

  const [pageTitle, setPageTitle] = useState("Product Mass Update");

  const titleMap = [
    { path: "/", title: "Product Mass Update" },
    { path: "/admin", title: "Product Mass Update - Logs" },
    {
      path: "/approval-queues",
      title: "Product Mass Update - Approval Queues"
    },
    {
      path: "/user-guide",
      title: "Product Mass Update - User Guide"
    },
    { path: "/no-access", title: "Not authorized" }
  ];

  const route = titleMap.find((item) =>
    matchPath(item.path, location.pathname)
  );

  useEffect(() => {
    if (route && route.title) {
      setPageTitle(route.title);
      document.title = route.title;
    }
  }, [route]);

  return (
    <div>
      {/* Page Wrapper */}
      <div id="wrapper">
        <SideNav toggle={toggleMenu} showMenu={showMenu} />

        {/* Content Wrapper */}
        <div id="content-wrapper" className="d-flex flex-column">
          {/* Main Content */}
          <div id="content">
            <TopNav toggle={toggleMenu} title={pageTitle} />
            <div id="modal-container" className="pt-4">
              {/* Begin Page Content */}
              <div id="main-content" className="container-fluid">
                <ToastContainer
                  hideProgressBar
                  newestOnTop
                  draggable={false}
                  theme="colored"
                  transition={Zoom}
                />
                {children}
              </div>
            </div>
            {/* /.container-fluid */}
          </div>
          {/* End of Main Content */}

          {/* Footer */}
          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                <span>Copyright &copy; Tupperware 2022</span>
              </div>
            </div>
          </footer>
          {/* End of Footer */}
        </div>
        {/* End of Content Wrapper */}
      </div>
      {/* End of Page Wrapper */}
    </div>
  );
};

export default Layout;
