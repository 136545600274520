import axios from "axios";
import jwt_decode from "jwt-decode";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { Predicates } from "../libraries/predicates/predicates";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const useAxios = () => {
  let refreshTokenPromise; // this holds in-progress token refresh requests

  const { authTokens, setUser, setAuthTokens, logoutUser } =
    useContext(AuthContext);
  const axiosInstance = axios.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${authTokens?.access}`,
      Accept: "application/json",
    },
  });

  const getRefreshToken = () =>
    axios.post(`${baseURL}/token/refresh/`, {
      refresh: authTokens.refresh,
    });

  axiosInstance.interceptors.response.use(
    (res) => res,
    (error) => {
      if (error.config && error.response && error.response.status === 401) {
        if (!refreshTokenPromise) {
          refreshTokenPromise = getRefreshToken().then((res) => {
            refreshTokenPromise = null;
            return res;
          });
        }

        return refreshTokenPromise
          .then((res) => {
            localStorage.setItem("authTokens", JSON.stringify(res.data));
            setAuthTokens(res.data);
            setUser(jwt_decode(res.data.access));
            error.config.headers.Authorization = `Bearer ${res.data.access}`;
            return axiosInstance.request(error.config);
          })
          .catch(() => {
            const hasAuthTokens = Predicates.isNotNullAndNotUndefined(
              localStorage.getItem("authTokens")
            );

            if (hasAuthTokens) {
              logoutUser();
            }
          });
      }
      return Promise.reject(error);
    }
  );

  // axiosInstance.interceptors.request.use(async (req) => {
  //   const user = jwt_decode(authTokens?.access);
  //   const isExpired = Date.now() >= user.exp * 1000;

  //   if (!isExpired) {
  //     return req;
  //   }
  //    else {
  //     const response = await axios.post(`${baseURL}/token/refresh/`, {
  //       refresh: authTokens.refresh
  //     });

  //     localStorage.setItem("authTokens", JSON.stringify(response.data));

  //     setAuthTokens(response.data);
  //     setUser(jwt_decode(response.data.access));

  //     req.headers.Authorization = `Bearer ${response.data.access}`;
  //     return req;
  //   }
  // });

  return axiosInstance;
};

export default useAxios;
