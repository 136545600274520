import {
  useMemo,
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { AgGridReact } from "ag-grid-react";
import useAxios from "../utils/useAxios";
import AuthContext from "../context/AuthContext";
import ApproveConfirmationModal from "./ApproveConfirmationModal";
import ReactModal from "react-modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faCircleXmark,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

import TablePagination from "./TablePagination";
import { FilterChangedEvent } from "ag-grid-community";

const ApprovalDetails = ({ queueId }: { queueId: string }) => {
  const axios = useAxios();
  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [queue, setQueue] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState("");

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = (action: string) => {
    setShowModal(true);
    setAction(action);
  };

  const fetchQueueAsync = async () => {
    setIsLoading(true);
    try {
      const [queue, products] = await Promise.all([
        axios.get(`queues/${queueId}/`),
        axios.get(`queues/${queueId}/products/`),
      ]);
      if (queue.status === 200) {
        setQueue(queue.data);
      }
      if (products.status === 200) {
        setRowData(products.data);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    ReactModal.setAppElement("#root");
    fetchQueueAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnDefs = useMemo(
    () => [
      {
        headerName: "SKU nº",
        maxWidth: 140,
        minWidth: 120,
        sort: "asc" as const,
        cellRenderer: (params: any) => {
          return (
            <>
              <span>{`11 ${params.data.product_id}`}</span>
            </>
          );
        },
      },
      {
        field: "name",
        headerName: "Local Description",
        minWidth: 135,
      },
      { field: "molds", headerName: "Mold Composition" },
      { field: "fields", headerName: "Field(s)" },
      { field: "previous_values", headerName: "Previous Value(s)" },
      { field: "news_values", headerName: "New Value(s)" },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      wrapText: true,
      autoHeight: true,
      suppressMovable: true,
      suppressMenu: true,
      icons: {
        sortAscending: "<i class='fa fa-sort-up'/>",
        sortDescending: "<i class='fa fa-sort-down'/>",
        sortUnSort: "<i class='fa fa-sort' style='color:#e3e6f0'></i>",
      },
    }),
    []
  );

  const onPaginationChanged = useCallback(() => {
    if (gridRef.current?.api) {
      setPageSize(gridRef.current.api.paginationGetPageSize());
      setCurrentPage(gridRef.current.api.paginationGetCurrentPage() + 1);
      setTotalPages(gridRef.current.api.paginationGetTotalPages());
      setTotalEntries(gridRef.current.api.getDisplayedRowCount());
    }
  }, [gridRef]);

  const handleTableSearch = (e: any) => {
    const searchString = e.target.value.toLowerCase().trim();
    gridRef.current?.api.setQuickFilter(searchString);
  };

  const onFilterChanged = useCallback((event: FilterChangedEvent) => {
    event.api?.getDisplayedRowCount() === 0
      ? event.api.showNoRowsOverlay()
      : event.api.hideOverlay();
  }, []);

  const overlayNoRowsTemplate = useMemo(
    () => "<span>No results found with these criteria<span>",
    []
  );

  return (
    <>
      <ReactModal
        isOpen={showModal}
        className="custom-modal"
        overlayClassName="custom-overlay"
      >
        <ApproveConfirmationModal
          handleCloseModal={closeModal}
          action={action}
          queueId={queueId}
          refetchQueue={fetchQueueAsync}
        />
      </ReactModal>
      <div className="d-flex justify-content-between mb-2">
        <div></div>
        <h3 className="h5 mb-2">Request Details</h3>
        <Link to="/approval-queues" className="icon-button close-cross">
          <FontAwesomeIcon icon={faClose} />
        </Link>
      </div>
      <div className="d-flex justify-content-between align-items-end">
        {queue && (
          <div>
            <p>Request ID: {queue.id} </p>
            <p>Request Issuer: {queue.username}</p>
          </div>
        )}
        <div
          id="pp_search_filter"
          className="dataTables_filter table-top-search"
        >
          <label>
            Search:
            <input
              onChange={handleTableSearch}
              type="search"
              className="table-top-input"
              placeholder=""
              aria-controls="pp_search"
            />
          </label>
        </div>
      </div>
      <div className="table-responsive">
        <div className="ag-theme-alpine">
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <p>Loading Data... </p>
            </div>
          ) : (
            <>
              <AgGridReact
                domLayout="autoHeight"
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                suppressRowClickSelection
                pagination={true}
                paginationPageSize={10}
                onPaginationChanged={onPaginationChanged}
                unSortIcon
                suppressPaginationPanel
                onFilterChanged={onFilterChanged}
                overlayNoRowsTemplate={overlayNoRowsTemplate}
              ></AgGridReact>
            </>
          )}
        </div>
        <TablePagination
          gridRef={gridRef}
          totalEntries={totalEntries}
          pageSize={pageSize}
          currentPage={currentPage}
          totalPages={totalPages}
        />
        {hasPermission("api.approve_pmu_queue") &&
        queue?.status === "Pending" ? (
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-danger mr-2"
              onClick={() => openModal("reject")}
            >
              <FontAwesomeIcon
                className="mr-2"
                icon={faCircleXmark}
                size="lg"
              />{" "}
              <span>Reject</span>
            </button>
            <button
              className="btn btn-success"
              onClick={() => openModal("accept")}
            >
              <FontAwesomeIcon
                className="mr-2"
                icon={faCircleCheck}
                size="lg"
              />
              <span>Accept</span>
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default ApprovalDetails;
