import CheckBox from "./CheckBox";

const SelectAllCellRenderer = ({ api }: { api: any }) => {
  const selectAll = (checked: boolean) => {
    if (checked) {
      api.selectAllFiltered();
    } else {
      api.deselectAllFiltered();
    }
  };

  return (
    <div className="text-center">
      <CheckBox selectAll={selectAll} api={api} />
    </div>
  );
};

export default SelectAllCellRenderer;
