import Highlighter from "react-highlight-words";

const OptionFormatter = (option: { value: any; label: string }, input: any) => {
  return (
    <Highlighter
      searchWords={[
        input.selectValue.length >= 5 ? "" : input.inputValue.trim()
      ]}
      autoEscape={true}
      textToHighlight={option.label}
      highlightClassName={"active-search-string"}
    />
  );
};

export default OptionFormatter;
