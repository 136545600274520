import { useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import useAxios from "../utils/useAxios";
const ApproveConfirmationModal = ({
  handleCloseModal,
  action,
  queueId,
  refetchQueue
}: {
  handleCloseModal: any;
  action: string;
  queueId: string;
  refetchQueue: any;
}) => {
  const axios = useAxios();
  const navigate = useNavigate();
  const onCancel = () => {
    handleCloseModal();
  };
  const onConfirm = () => {
    updateStatus();
  };

  const [comment, setComment] = useState("");
  const [errors, setErrors] = useState<any>({});
  const handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) =>
    setComment(e.target.value);

  const updateStatus = async () => {
    try {
      const data =
        action === "accept"
          ? { status: "Approved" }
          : action === "reject"
          ? { status: "Rejected", comment }
          : null;

      await axios({
        method: "put",
        url: `/queues/${queueId}/`,
        data
      });
      handleCloseModal();
      navigate("/approval-queues/");
    } catch (err: any) {
      setErrors(err.response.data);
      console.log(err);
    }
  };

  return (
    <div className="text-center p-5 m-4 d-flex flex-column justify-content-center align-items-center h-100">
      {action === "accept" && (
        <p className="text-center mb-5">
          Are you sure you want to{" "}
          <span style={{ color: "#95C62E", fontWeight: "bolder" }}>
            approve
          </span>{" "}
          this Product Mass Update request? If you proceed, all requested
          modifications will be applied and values will be updated.
        </p>
      )}
      {action === "reject" && (
        <>
          <p className="text-center mb-4">
            Are you sure you want to{" "}
            <span style={{ color: "#e74a3b", fontWeight: "bold" }}>reject</span>{" "}
            this Product Mass Update request? If you proceed, no modification
            will be applied and current values will be maintained.
          </p>
          <div className="form-inline mb-4">
            <label
              className="form-label mr-2"
              style={errors.comment ? { marginBottom: "19px" } : {}}
            >
              Comment:{" "}
            </label>
            <div>
              <textarea
                onChange={handleCommentChange}
                className={
                  errors.comment ? "is-invalid form-control" : "form-control"
                }
                rows={1}
                maxLength={255}
              />
              <div className="invalid-feedback">
                {errors.comment ? errors.comment : ""}
              </div>
            </div>
          </div>
        </>
      )}
      <div className="d-flex justify-content-center align-center">
        <button onClick={onConfirm} type="button" className="btn btn-primary">
          Confirm and {action}
        </button>
        <button
          onClick={onCancel}
          type="button"
          className="btn btn-secondary ml-3"
          data-dismiss="modal"
        >
          Cancel, go back
        </button>
      </div>
    </div>
  );
};

export default ApproveConfirmationModal;
