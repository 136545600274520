import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";

const MoldCompCellRenderer = (props: any) => {
  const handleClick = (event: any) => {
    props.handeleMoldDetails(props.data);
  };
  let { mold_qty, non_mold_qty } = props.data;
  const componentsQty: string = `${mold_qty > 0 ? mold_qty : "0"}+${
    non_mold_qty > 0 ? non_mold_qty : "0"
  }`;

  return (
    <div className="mold-comp-cell d-flex justify-content-between">
      <span>{componentsQty}</span>
      {mold_qty > 0 || non_mold_qty > 0 ? (
        <button className="icon-button" onClick={handleClick}>
          <FontAwesomeIcon icon={faCirclePlus} />
          <small className="ml-1">Details</small>
        </button>
      ) : null}
    </div>
  );
};

export default MoldCompCellRenderer;
