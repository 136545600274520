const CustomTooltip = (props: any) => {
  const data = props.api.getDisplayedRowAtIndex(props.rowIndex).data;
  if (data.last_change) {
    return (
      <div className="changes-tooltip">
        <p>{data.last_change}</p>
      </div>
    );
  }
};

export default CustomTooltip;
