import { useMemo, useState, useRef, useCallback, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import TablePagination from "./TablePagination";
import DetailsCellRenderer from "../components/DetailsCellRenderer";
import { formatDateValue, getChangesValue } from "../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilterCircleXmark,
  faRotate,
} from "@fortawesome/free-solid-svg-icons";
import { FilterChangedEvent } from "ag-grid-community";

const ApprovalQueuesTable = ({
  data,
  refreshData,
  floatingFilter,
  setFloatingFilter,
}: {
  data: any[];
  refreshData: any;
  floatingFilter: any;
  setFloatingFilter: any;
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [isFiltered, setIsFiltered] = useState(false);

  const columnDefs = useMemo(
    () => [
      { field: "id", headerName: "ID", maxWidth: 100 },
      {
        headerName: "Change Request",
        minWidth: 180,
        field: "changes",
        valueGetter: (params: any) => getChangesValue(params.data),
      },
      {
        headerName: "Request Date",
        valueGetter: (params: any) => new Date(params.data.created_at),
        valueFormatter: (params: any) =>
          formatDateValue(params.data.created_at),
        sort: "desc" as const,
        minWidth: 210,
      },
      { field: "username", headerName: "Request Issuer", minWidth: 180 },
      { field: "approver", headerName: "Request Approver", minWidth: 185 },
      { field: "status" },
      { field: "comment", minWidth: 120 },
      {
        headerName: "Details",
        maxWidth: 100,
        cellRenderer: DetailsCellRenderer,
        sortable: false,
        filter: false,
        floatingFilter: false,
      },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      filter: true,
      filterParams: { buttons: ["reset"] },
      floatingFilter: true,
      sortable: true,
      flex: 1,
      wrapText: true,
      autoHeight: true,
      suppressMovable: true,
      suppressMenu: true,
      minWidth: 100,
      icons: {
        sortAscending: "<i class='fa fa-sort-up'/>",
        sortDescending: "<i class='fa fa-sort-down'/>",
        sortUnSort: "<i class='fa fa-sort' style='color:#e3e6f0'></i>",
        filter: "<i class='fa fa-filter'></i>",
      },
    }),
    []
  );

  const onPageSizeChanged = useCallback((e: any) => {
    gridRef.current?.api.paginationSetPageSize(Number(e.target.value));
    setPageSize(Number(e.target.value));
  }, []);

  const onPaginationChanged = useCallback(() => {
    if (gridRef.current?.api) {
      setPageSize(gridRef.current.api.paginationGetPageSize());
      setCurrentPage(gridRef.current.api.paginationGetCurrentPage() + 1);
      setTotalPages(gridRef.current.api.paginationGetTotalPages());
      setTotalEntries(gridRef.current.api.getDisplayedRowCount());
    }
  }, [gridRef]);

  const handleTableSearch = (e: any) => {
    const searchString = e.target.value.toLowerCase().trim();
    gridRef.current?.api.setQuickFilter(searchString);
  };

  const clearAllFilters = useCallback(() => {
    gridRef?.current?.api.setFilterModel(null);
  }, [gridRef]);

  const onFilterChanged = (event: FilterChangedEvent) => {
    const filter = gridRef.current?.api.getFilterModel();
    if (filter) {
      setIsFiltered(Object.keys(filter).length !== 0);
      setFloatingFilter(filter);
    }
    event.api?.getDisplayedRowCount() === 0
      ? event.api.showNoRowsOverlay()
      : event.api.hideOverlay();
  };

  const onGridReady = () => {
    if (floatingFilter) {
      gridRef.current?.api.setFilterModel(floatingFilter);
    }
  };

  const overlayNoRowsTemplate = useMemo(
    () => "<span>No results found with these criteria<span>",
    []
  );

  useEffect(() => {
    setRowData(data);
  }, [data]);

  return (
    <>
      <div className="d-flex justify-content-between table-top-container">
        <div className="d-flex align-items-center">
          <label className="d-inline-block">
            Show
            <select
              onChange={onPageSizeChanged}
              className="table-top-input"
              id="page-size"
              value={pageSize}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            entries
          </label>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div
            id="pp_search_filter"
            className="dataTables_filter table-top-search"
          >
            <label>
              Search:
              <input
                onChange={handleTableSearch}
                type="search"
                className="table-top-input"
                placeholder=""
                aria-controls="pp_search"
              />
            </label>
          </div>
          <button className="icon-button ml-3" onClick={refreshData}>
            <FontAwesomeIcon size="lg" icon={faRotate} />
          </button>
          {/*<button
            className="icon-button ml-3 mr-3"
            onClick={clearAllFilters}
            disabled={!isFiltered}
          >
            <FontAwesomeIcon size="lg" icon={faFilterCircleXmark} />
          </button>*/}
        </div>
      </div>
      <div>
        <div className="ag-theme-alpine">
          <AgGridReact
            domLayout="autoHeight"
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            suppressRowClickSelection
            pagination={true}
            paginationPageSize={pageSize}
            onGridReady={onGridReady}
            onPaginationChanged={onPaginationChanged}
            onFilterChanged={onFilterChanged}
            unSortIcon
            suppressPaginationPanel
            overlayNoRowsTemplate={overlayNoRowsTemplate}
          ></AgGridReact>
        </div>
        <TablePagination
          gridRef={gridRef}
          pageSize={pageSize}
          currentPage={currentPage}
          totalPages={totalPages}
          totalEntries={totalEntries}
        />
      </div>
    </>
  );
};

export default ApprovalQueuesTable;
