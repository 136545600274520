import { SyntheticEvent, useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { IProduct, IProductHier, ISelectOption } from "../types/data.interface";
import { mapGlobalProductOptions, mapTccOptions } from "../helpers";
import Spinner from "./Spinner";
import OptionFormatter from "./OptionFormatter";
import NotificationContext from "../context/NotificationContext";
import useAxios from "../utils/useAxios";
import { useOutletContext } from "react-router-dom";
import AsyncSelect from "react-select/async";
import {
  DebounceContext,
  DebounceContextType,
} from "../context/DebounceContext";

const MassUpdateModal = ({
  gridRef,
  selectedRows,
  categories,
  subCategories,
  productLines,
  globalProducts,
  tccs,
  handleCloseModal,
}: {
  gridRef: any;
  selectedRows: IProduct[] | [];
  categories: IProductHier[];
  subCategories: IProductHier[];
  productLines: IProductHier[];
  globalProducts: any[];
  tccs: any[];
  handleCloseModal: any;
}) => {
  const axios = useAxios();
  const { searchDebounce } = useContext<DebounceContextType>(DebounceContext);
  const { refetchData } = useOutletContext<any>();
  const [loading, setLoading] = useState(false);
  const notificationCtx = useContext(NotificationContext);
  const fetchNotifications = notificationCtx?.fetchNotifications;

  const [status, setStatus] = useState<
    | null
    | "Failed"
    | "Enqueued"
    | "Completed"
    | "In Progress"
    | "Approved"
    | "Declined"
  >(null);
  // const [filteredSubCategories, setFilteredSubCategories] =
  //   useState<IProductHier[]>(subCategories);
  // const [categorySelected, setCategorySelected] =
  //   useState<ISelectOption | null>(null);
  // const [subCategorySelected, setSubCategorySelected] =
  //   useState<ISelectOption | null>(null);
  // const [productLineSelected, setProductLineSelected] =
  //   useState<ISelectOption | null>(null);
  // const [parentCodeValue, setParentCodeValue] = useState<string>("");
  //const [globalNameValue, setGlobalNameValue] = useState<string>("");

  const [filteredTccs, setFilteredTccs] = useState<any[]>(tccs);
  const [globalProductSelected, setGlobalProductSelected] = useState<
    any | null
  >(null);
  const [tccSelected, setTccSelected] = useState<any | null>(null);
  const [isFilteredTccsLoading, setIsFilteredTccsLoading] =
    useState<boolean>(false);

  const buttonDisabled = !tccSelected ? true : false;

  // const handleCategoryChange = (e: ISelectOption | null) => {
  //   setSubCategorySelected(null);
  //   if (e) {
  //     setCategorySelected(e);
  //     setFilteredSubCategories(
  //       subCategories.filter(
  //         (item: IProductHier) => item.parent?.id === e.value
  //       )
  //     );
  //   } else {
  //     setCategorySelected(null);
  //     setFilteredSubCategories(subCategories);
  //   }
  // };

  // const handleSubCategoryChange = (e: ISelectOption | null) => {
  //   if (e) {
  //     setSubCategorySelected(e);
  //     const { id, code, name } = subCategories.find(
  //       (item) => item.id === e.value
  //     )?.parent!;
  //     setCategorySelected({
  //       value: id,
  //       label: code + " - " + name,
  //     });
  //   } else {
  //     setSubCategorySelected(null);
  //   }
  // };

  // const handleProductLineChange = (e: ISelectOption | null) => {
  //   if (e) {
  //     setProductLineSelected(e);
  //   } else {
  //     setProductLineSelected(null);
  //   }
  // };

  // const handleParentCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const cleanedCode = e.target.value.replace(/[^a-z0-9-' ().:_&,"#/]/gi, "");
  //   setParentCodeValue(cleanedCode);
  // };

  // const handleGlobalNameChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const cleanedName = e.target.value.replace(/[^a-z0-9-' ().:_&,"#/]/gi, "");
  //   setGlobalNameValue(cleanedName);
  // };

  const handleGlobalProductChange = async (e: any | null) => {
    if (e) {
      setGlobalProductSelected(e);
      setFilteredTccs(filteredTccs);
    } else {
      setGlobalProductSelected(null);
      setTccSelected(null);
      setFilteredTccs(tccs);
    }
  };

  useEffect(() => {
    const getFilteredTccs = async () => {
      setIsFilteredTccsLoading(true);
      const search = new URLSearchParams({
        limit: "100",
        global_product: globalProductSelected.value.id ?? "",
      });
      const response = await axios.get(`/tccs/?${search}`);
      if (response.status === 200) {
        setFilteredTccs(response.data.results);
      } else {
        setFilteredTccs([]);
      }
      setIsFilteredTccsLoading(false);
    };

    if (globalProductSelected) {
      getFilteredTccs();
    } else {
      setFilteredTccs(tccs);
    }
  }, [globalProductSelected]);

  const handleTccChange = (e: any | null) => {
    if (e) {
      setTccSelected(e);
      const globalProductAssociated = e.value.global_product;
      if (globalProductAssociated && !globalProductSelected) {
        setGlobalProductSelected(
          mapGlobalProductOptions([globalProductAssociated])?.at(0) ?? null
        );
      }
    } else {
      setTccSelected(null);
    }
  };

  const loadGlobalProducts = async (input: string, callback: any) => {
    let options: any = mapGlobalProductOptions(globalProducts);
    if (input.length > 2) {
      const search = new URLSearchParams({ search: input, limit: "100" });
      const response = await axios.get(`/global-products/?${search}`);
      if (response.status === 200) {
        options = mapGlobalProductOptions(response.data.results);
      }
    }
    callback(options);
  };
  const loadTccs = async (input: string, callback: any) => {
    let options: any = mapTccOptions(filteredTccs);
    if (input.length > 2) {
      const search = new URLSearchParams({
        search: input,
        limit: "100",
        global_product: globalProductSelected?.value?.id ?? "",
      });
      const response = await axios.get(`/tccs/?${search}`);
      if (response.status === 200) {
        options = mapTccOptions(response.data.results);
      }
    }
    callback(options);
  };

  const onClose = () => {
    handleCloseModal();
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    startMassUpdate();
    // checks for new Notification after 1.15 min
    setTimeout(() => {
      fetchNotifications();
    }, 61500);
  };

  const startMassUpdate = async () => {
    let payload: any = {};
    // if (subCategorySelected) {
    //   payload.sub_category = subCategorySelected.value;
    // }
    // if (productLineSelected) {
    //   payload.product_line = productLineSelected.value;
    // }
    // if (globalNameValue) {
    //   payload.gp_name = globalNameValue;
    // }
    // if (parentCodeValue) {
    //   payload.parent_code = parentCodeValue;
    // }

    if (tccSelected) {
      payload.tcc = tccSelected.value.id;
    }

    try {
      setLoading(true);
      const queueResponse = await axios({
        method: "post",
        url: `/pmu-queues/`,
        data: payload,
      });

      const productsIds = selectedRows.map((product: any) => {
        return product.id;
      });

      if (queueResponse.status === 201) {
        const queueId = await queueResponse.data.id;

        const response = await axios({
          method: "post",
          url: `/pmu-queues/${queueId}/products/`,
          data: { items: productsIds },
        });

        if (response.status === 201) {
          const statusRes = await axios({
            url: `/pmu-queues/${queueId}/`,
          });
          setStatus(statusRes.data.status);
        }
      }
      refetchData();
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <div className="p-4 d-flex flex-column h-100">
      <div className="d-flex justify-content-between mb-3">
        <h3 className="h5">
          {!status
            ? "New Values Selection for Product Mass Update"
            : `Product Mass Update - ${status}`}
        </h3>

        <button onClick={onClose} className="icon-button close-cross">
          <FontAwesomeIcon icon={faClose} />
        </button>
      </div>
      {loading ? (
        <Spinner />
      ) : !status ? (
        <>
          <p className="mb-4">
            The value(s) selected will be applied on all items selected from the
            previous screen.
            <br /> If blank is selected, the current value will be maintained.
          </p>

          <form
            className="d-flex flex-column gx-2 gy-3 mt-2 h-100"
            onSubmit={onSubmit}
          >
            {/* <div className="row form-row">
              <div className="col-md-4 form-group">
                <label className="form-label">Category</label>
                <Select
                  options={mapActiveProductHierOptions(categories)}
                  onChange={handleCategoryChange}
                  formatOptionLabel={OptionFormatter}
                  value={categorySelected}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-md-4 form-group">
                <label className="form-label">Sub-Category</label>
                <Select
                  options={mapActiveProductHierOptions(filteredSubCategories)}
                  onChange={handleSubCategoryChange}
                  formatOptionLabel={OptionFormatter}
                  value={subCategorySelected}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="col-md-4 form-group">
                <label className="form-label">Product Line</label>
                <Select
                  options={mapActiveProductHierOptions(productLines)}
                  onChange={handleProductLineChange}
                  formatOptionLabel={OptionFormatter}
                  value={productLineSelected}
                  classNamePrefix="react-select"
                  placeholder=""
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div> */}

            <div className="row form-row">
              {/* <div className="col-md-4 form-group">
                <label className="form-label">Parent Code</label>
                <input
                  onChange={handleParentCodeChange}
                  value={parentCodeValue}
                  maxLength={50}
                  type="text"
                  name="parentCode"
                  className="form-control"
                />
              </div> */}

              <div className="col-md form-group">
                <label className="form-label">Global Product</label>
                <AsyncSelect
                  cacheOptions
                  loadOptions={(input, callback) => {
                    searchDebounce(
                      loadGlobalProducts,
                      input,
                      mapGlobalProductOptions(globalProducts) ?? [],
                      callback
                    );
                  }}
                  defaultOptions={mapGlobalProductOptions(globalProducts)}
                  onChange={handleGlobalProductChange}
                  value={globalProductSelected}
                  formatOptionLabel={OptionFormatter}
                  classNamePrefix="react-select"
                  placeholder="(min 3 characters)"
                  isClearable
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>

            <div className="row form-row">
              <div className="col-md form-group">
                <label className="form-label">TCC</label>
                <AsyncSelect
                  cacheOptions
                  loadOptions={(input, callback) => {
                    searchDebounce(
                      loadTccs,
                      input,
                      mapTccOptions(filteredTccs),
                      callback
                    );
                  }}
                  defaultOptions={mapTccOptions(filteredTccs)}
                  onChange={handleTccChange}
                  value={tccSelected}
                  formatOptionLabel={OptionFormatter}
                  classNamePrefix="react-select"
                  placeholder="(min 3 characters)"
                  isClearable
                  isLoading={isFilteredTccsLoading}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center mt-auto">
              <div style={{ width: "78px" }}></div>
              <p className="m-0">
                {selectedRows.length} entries selected for update
              </p>
              <input
                disabled={buttonDisabled}
                type="submit"
                className="btn btn-primary "
                value="Update"
              />
            </div>
          </form>
        </>
      ) : (
        <>
          <div className="text-center my-auto">
            {status === "Approved" || status === "In Progress" ? (
              <>
                <FontAwesomeIcon icon={faSpinner} size="2x" className="mb-4" />
                <p className="text-center mb-5">
                  Update in progress.
                  <br />
                  You will be notified when the update is finished in the
                  Notifications panel.
                  <br />
                  You can close this window.
                </p>
              </>
            ) : status === "Completed" ? (
              <p className="text-center mb-5">
                Update is completed.
                <br />
                You can close this window.
              </p>
            ) : status !== "Failed" ? (
              <p className="text-center mb-5">
                Your request has been sent to an Approval Queue.
                <br />
                You will be notified about the request status in the
                Notification panel.
                <br />
                You can close this window.
              </p>
            ) : (
              <p className="text-center mb-5">
                Something went wrong.
                <br />
                Please contact an admin or check logs for details.
                <br />
                You can close this window.
              </p>
            )}
          </div>
          <div className="d-flex justify-content-end">
            <button onClick={onClose} type="button" className="btn btn-primary">
              Close
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default MassUpdateModal;
