import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp, faListCheck } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useMatch } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";

function SideNav({ showMenu, toggle }: { showMenu: boolean; toggle: any }) {
  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;

  const pmuActiveLink = useMatch("/") ? "nav-item active" : "nav-item";
  const approvalsActiveLink = useMatch("/approval-queues/*")
    ? "nav-item active"
    : "nav-item";
  const adminActiveLink = useMatch("/admin/*") ? "nav-item active" : "nav-item";
  const userGuideActiveLink = useMatch("/user-guide")
    ? "nav-item active"
    : "nav-item";
  return (
    <ul
      className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${
        showMenu ? "" : "toggled"
      }`}
      id="accordionSidebar"
    >
      {/* Sidebar - Brand */}
      <NavLink
        className="sidebar-brand d-flex align-items-center justify-content-center"
        to="/"
      >
        <div className="sidebar-brand-icon">
          <i className="fas fa-home"></i>
        </div>
        {/* <div className="sidebar-brand-text mx-3">Home</div> */}
      </NavLink>

      {/* Divider */}
      <hr className="sidebar-divider" />

      {/* Nav Item - PMU */}
      <li className={pmuActiveLink}>
        <NavLink className="nav-link" to="/">
          <FontAwesomeIcon icon={faFileArrowUp} className="fa fa-fw" />
          <span>Product Mass Update</span>
        </NavLink>
      </li>

      {/* Nav Item - Approval Queues */}
      {hasPermission("api.view_pmuqueue") && (
        <li className={approvalsActiveLink}>
          <NavLink className="nav-link" to="/approval-queues">
            <FontAwesomeIcon icon={faListCheck} className="fa fa-fw" />
            <span>Approval Queues</span>
          </NavLink>
        </li>
      )}

      {/* Nav Item - Admin */}
      {hasPermission("api.logs_pmu_queue") && (
        <li className={adminActiveLink}>
          <NavLink className="nav-link" to="/admin">
            <i className="fas fa-fw fa-user-shield"></i>
            <span>Admin Logs</span>
          </NavLink>
        </li>
      )}

      {/* Nav Item - User Guide */}
      {/* <li className={userGuideActiveLink}>
        <NavLink className="nav-link" to="/user-guide">
          <i className="fas fa-fw fa-book"></i>
          <span>User Guide</span>
        </NavLink>
      </li> */}

      {/* Divider */}
      <hr className="sidebar-divider d-none d-md-block" />

      {/* Sidebar Toggler (Sidebar) */}
      <div className="text-center d-none d-md-inline">
        <button
          className="rounded-circle border-0"
          onClick={toggle}
          id="sidebarToggle"
        ></button>
      </div>
    </ul>
  );
}

export default SideNav;
