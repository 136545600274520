import { ReactNode, createContext, useState } from "react";

type DebounceContextType = {
  debounce: (func: any, delay: number) => (...args: any) => void;
  searchDebounce: (
    func: any,
    search: string,
    defaultValuesList: any[],
    callback: any,
    minLength?: number
  ) => void;
};

const DebounceContext = createContext<DebounceContextType | any>(null);

const DebounceContextProvider = ({ children }: { children: ReactNode }) => {
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const debounce = (func: any, delay: number) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
    return (...args: any[]) => {
      let newTimeoutId = setTimeout(() => {
        func(...args);
      }, delay);
      setTimeoutId(newTimeoutId);
    };
  };

  const searchDebounce = (
    func: any,
    search: string,
    defaultValuesList: any[],
    callback: any,
    minLength?: number
  ) => {
    if ((minLength && search.length < minLength) || search.length < 3) {
      callback(defaultValuesList);
    } else {
      debounce(func, 550)(search, callback);
    }
  };

  return (
    <DebounceContext.Provider value={{ debounce, searchDebounce }}>
      {children}
    </DebounceContext.Provider>
  );
};

export {
  DebounceContextProvider as default,
  DebounceContext,
  type DebounceContextType,
};
