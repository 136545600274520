import { useMemo, useState, useRef, useCallback, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { getChangesValue, formatDateValue } from "../helpers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRotate,
  faFilterCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

import TablePagination from "./TablePagination";
import DetailsCellRenderer from "./DetailsCellRenderer";
import { FilterChangedEvent } from "ag-grid-community";

const AdminLogsTable = ({
  data,
  refreshData,
  floatingFilter,
  setFloatingFilter,
}: {
  data: any[];
  refreshData: any;
  floatingFilter: any;
  setFloatingFilter: any;
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [isFiltered, setIsFiltered] = useState(false);

  const columnDefs = useMemo(
    () => [
      { field: "id", headerName: "ID", maxWidth: 95 },
      { field: "name", headerName: "Name" },
      {
        field: "changes",
        minWidth: 140,
        headerName: "Change",
        valueGetter: (params: any) => getChangesValue(params.data),
      },
      {
        field: "start",
        minWidth: 220,
        valueGetter: (params: any) =>
          params.data.start ? new Date(params.data.start) : new Date(),
        valueFormatter: (params: any) => formatDateValue(params.data.start),
        sort: "desc" as const,
      },
      {
        field: "end",
        minWidth: 220,
        valueGetter: (params: any) =>
          params.data.end ? new Date(params.data.end) : new Date(),
        valueFormatter: (params: any) => formatDateValue(params.data.end),
      },
      { field: "username", headerName: "User" },
      { field: "status", headerName: "Status" },
      {
        headerName: "Details",
        maxWidth: 100,
        sortable: false,
        filter: false,
        floatingFilter: false,
        cellRenderer: DetailsCellRenderer,
      },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      filter: true,
      filterParams: { buttons: ["reset"] },
      sortable: true,
      flex: 1,
      wrapText: true,
      autoHeight: true,
      suppressMovable: true,
      floatingFilter: true,
      minWidth: 120,
      suppressMenu: true,
      icons: {
        sortAscending: "<i class='fa fa-sort-up'/>",
        sortDescending: "<i class='fa fa-sort-down'/>",
        sortUnSort: "<i class='fa fa-sort' style='color:#e3e6f0'></i>",
        filter: "<i class='fa fa-filter'></i>",
      },
    }),
    []
  );

  const onPageSizeChanged = useCallback((e: any) => {
    gridRef.current?.api.paginationSetPageSize(Number(e.target.value));
    setPageSize(Number(e.target.value));
  }, []);

  const onPaginationChanged = useCallback(() => {
    if (gridRef.current?.api) {
      setPageSize(gridRef.current.api.paginationGetPageSize());
      setCurrentPage(gridRef.current.api.paginationGetCurrentPage() + 1);
      setTotalPages(gridRef.current.api.paginationGetTotalPages());
      setTotalEntries(gridRef.current.api.getDisplayedRowCount());
    }
  }, [gridRef]);

  const handleTableSearch = (e: any) => {
    const searchString = e.target.value.toLowerCase().trim();
    gridRef.current?.api.setQuickFilter(searchString);
  };

  const clearAllFilters = useCallback(() => {
    gridRef?.current?.api.setFilterModel(null);
  }, []);

  const onFilterChanged = (event: FilterChangedEvent) => {
    const filter = gridRef?.current?.api.getFilterModel();
    if (filter) {
      setIsFiltered(Object.keys(filter).length !== 0);
      setFloatingFilter(filter);
    }

    event.api?.getDisplayedRowCount() === 0
      ? event.api.showNoRowsOverlay()
      : event.api.hideOverlay();
  };

  const onGridReady = () => {
    if (floatingFilter) {
      gridRef.current?.api.setFilterModel(floatingFilter);
    }
  };

  const overlayNoRowsTemplate = useMemo(
    () => "<span>No results found with these criteria<span>",
    []
  );

  useEffect(() => {
    setRowData(data);
  }, [data]);

  return (
    <>
      <div className="d-flex justify-content-between table-top-container">
        <div className="d-flex align-items-center">
          <label className="d-inline-block">
            Show
            <select
              onChange={onPageSizeChanged}
              className="table-top-input"
              id="page-size"
              value={pageSize}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            entries
          </label>
        </div>
        <div
          id="pp_search_filter"
          className="dataTables_filter table-top-search"
        >
          <label>
            Search:
            <input
              onChange={handleTableSearch}
              type="search"
              className="table-top-input"
              placeholder=""
              aria-controls="pp_search"
            />
          </label>
          <button className="icon-button ml-3" onClick={() => refreshData()}>
            <FontAwesomeIcon size="lg" icon={faRotate} />
          </button>
          {/* <button
            className="icon-button ml-3"
            onClick={clearAllFilters}
            disabled={!isFiltered}
          >
            <FontAwesomeIcon size="lg" icon={faFilterCircleXmark} />
          </button> */}
        </div>
      </div>
      <div className="ag-theme-alpine">
        <AgGridReact
          domLayout="autoHeight"
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          suppressRowClickSelection
          pagination={true}
          paginationPageSize={pageSize}
          onGridReady={onGridReady}
          onPaginationChanged={onPaginationChanged}
          onFilterChanged={onFilterChanged}
          unSortIcon
          suppressPaginationPanel
          overlayNoRowsTemplate={overlayNoRowsTemplate}
        ></AgGridReact>
      </div>
      <TablePagination
        gridRef={gridRef}
        pageSize={pageSize}
        currentPage={currentPage}
        totalPages={totalPages}
        totalEntries={totalEntries}
      />
    </>
  );
};

export default AdminLogsTable;
