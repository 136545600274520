import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { Outlet, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { mapFiltersToQueryString } from "./helpers";
import Layout from "./components/navigation/Layout";

import useAxios from "./utils/useAxios";
import AuthContext from "./context/AuthContext";
import NotificationContext from "./context/NotificationContext";

function App() {
  const authCtx = useContext(AuthContext);
  const user = authCtx?.user;
  const hasPermission = authCtx?.hasPermission;
  const axios = useAxios();
  const notificationCtx = useContext(NotificationContext);
  const fetchNotifications = notificationCtx?.fetchNotifications;
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [products, setProducts] = useState<any[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
  const [dataCatalog, setDataCatalog] = useState<any[]>([]);
  const [colors, setColors] = useState();
  const [materials, setMaterials] = useState();
  const [molds, setMolds] = useState();
  const [nonMolds, setNonMolds] = useState();
  const [globalProducts, setGlobalProducts] = useState<any[]>([]);
  const [tccs, setTccs] = useState<any[]>([]);

  const fetchAsync = async () => {
    setIsLoading(true);
    const endpoints = [
      `/data-catalog/?ordering=code`,
      `/colors/?limit=20`,
      `/materials/?limit=20`,
      `/molds/?limit=20`,
      `/non-molded-components/?limit=50`,
      `/global-products/?limit=100`,
      `/tccs/?limit=100`,
    ];
    try {
      const { data: productsRes } = await axios.get(`/products/?limit=20`);
      setProducts(productsRes.results);
      const [
        { data: dataCatalogRes },
        { data: colorsRes },
        { data: materialsRes },
        { data: moldsRes },
        { data: nonMoldsRes },
        { data: globalProductsRes },
        { data: tccsRes },
      ] = await Promise.all(endpoints.map((endpoint) => axios.get(endpoint)));
      setDataCatalog(dataCatalogRes);
      setColors(colorsRes.results);
      setMaterials(materialsRes.results);
      setMolds(moldsRes.results);
      setNonMolds(nonMoldsRes.results);
      setGlobalProducts(globalProductsRes.results);
      setTccs(tccsRes.results);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const filterProductsAsync = async (filters: any) => {
    const query = mapFiltersToQueryString(filters);
    setIsProductsLoading(true);
    try {
      const response = await axios.get(`/product-details/?${query}`);
      if (response) {
        setFilteredProducts(response.data);
      }
      setIsProductsLoading(false);
    } catch (err) {
      console.log(err);
      setIsProductsLoading(false);
    }
  };

  const refetchData = () => {
    if (user && hasPermission("api.view_product")) {
      fetchAsync();
    }
  };

  useEffect(() => {
    if (user && hasPermission("api.view_product")) {
      fetchAsync();
      fetchNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setFilteredProducts([]);
  }, [location.pathname]);

  return (
    <div className="App">
      <Layout>
        <Outlet
          context={{
            user,
            isLoading,
            isProductsLoading,
            refetchData,
            dataCatalog,
            products,
            colors,
            materials,
            molds,
            nonMolds,
            globalProducts,
            tccs,
            filteredProducts,
            filterProducts: filterProductsAsync,
          }}
        />
      </Layout>
    </div>
  );
}

export default App;
